<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="dialog = true"
    btnColor="success"
    :btnName="$t('_createCategory')"
    :btnTooltip="$t('_createCategory')"
    :title="$t('_createCategory')"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-alert type="error" :value="errors.length > 0" v-for="error in errors" :key="error.message">
        {{ error.message }}
      </v-alert>

      <v-layout wrap align-center>
        <v-flex xs12>
          <v-text-field :label="$t('_remoteId')" v-model="remoteId"></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field :label="$t('_name')" v-model="name" :rules="[rules.max200]" />
        </v-flex>

        <v-flex xs12>
          <v-text-field :label="$t('_description')" v-model="description" :rules="[rules.max200]" />
        </v-flex>

        <v-flex xs12>
          <v-select
            :items="categoryTypes"
            v-model="categoryType"
            :label="$tc('_categoryType')"
            item-text="name"
            item-value="name"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-flex xs12 sm12 md6>
        <v-btn @click="save" :disabled="!formValid || savingCategory" color="primary text-capitalize">{{
          $t('_save')
        }}</v-btn>
      </v-flex>
    </v-container>
  </ba-dialog>
</template>
<script>
import gql from 'graphql-tag';

import { types } from '../../contracts/categoryTypes';

export default {
  props: ['refresh'],
  data() {
    return {
      connectionId: 'Manual',
      dialog: false,
      name: '',
      description: '',
      remoteId: '',
      categoryTypes: types,
      categoryType: types[0].name,
      savingCategory: false,
      errors: [],
      rules: {
        max200: (v) => v.length <= 200 || this.$t('_maxTwentyChars'),
      },
    };
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.resetForm();
      }
    },
  },
  computed: {
    formValid() {
      return this.name.trim() !== '' && this.remoteId.trim() !== '';
    },
  },
  methods: {
    hideDialog() {
      this.dialog = false;
    },
    resetForm() {
      this.name = '';
      this.description = '';
      this.remoteId = '';
      this.categoryType = types[0].name;
      this.savingCategory = false;
      this.errors = [];
    },
    async save() {
      this.savingCategory = true;
      const orgId = this.$store.state.currentOrg.id;

      const category = {
        connectionId: this.connectionId,
        remoteId: this.remoteId,
        name: this.name,
        description: this.description,
        type: this.categoryType,
      };

      console.log('category: ', category);

      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateCategory($orgId: ID!, $category: CreateCategoryInput!) {
            createCategory(orgId: $orgId, category: $category) {
              success
              errors
            }
          }
        `,
        variables: {
          orgId,
          category,
        },
      });

      if (resp.data.createCategory.success) {
        this.resetForm();
        this.hideDialog();
        this.$emit('refresh');
      } else {
        resp.data.createCategory.errors.forEach((error) => {
          this.errors.push({ message: error });
        });
      }

      this.resetForm();
    },
  },
};
</script>
