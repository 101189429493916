<template>
  <v-layout row wrap>
    <v-flex class="pa-1" xs12>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="categories"
        :pagination.sync="pagination"
        :loading="categoriesIsLoading ? 'accent' : false"
        select-all
        item-key="title"
        class="elevation-3"
        :hide-actions="categories <= 5"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable text-xs-left',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon small>arrow_upward</v-icon>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr>
            <td>
              <v-switch
                v-model="props.item.enabled"
                @change="updateEnabledStatus(props.item)"
                v-if="checkScope(scopeLiterals.CategoriesUpdate)"
              ></v-switch>
            </td>
            <td width="40%">{{ props.item.name }}</td>
            <td class="text-sm-left" width="160px">
              <img v-if="props.item.source === 'Xero'" src="xero-small.png" style="height: 30px" />
              <img
                v-if="props.item.source === 'QuickBooks'"
                src="qb-logo-horizontal-preferred.svg"
                style="height: 40px"
              />
              <img v-if="props.item.source === 'NetSuite'" src="netsuite.png" style="height: 20px" />
              <img v-if="props.item.source === 'Manual'" src="logo.png" style="height: 15px" />
              <img v-if="props.item.source === 'SageIntacct'" src="sageintacct.png" style="height: 20px" />
            </td>
            <td class="text-xs-left">{{ props.item.type }}</td>
            <td class="text-xs-left">{{ props.item.code }}</td>
            <td class="text-xs-left">{{ props.item.id }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

import { ConnectionCategory } from '@/api-svc-types';

export default {
  props: [],
  components: {},
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      update(data) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
  data() {
    return {
      isLoading: false,
      pagination: {
        sortBy: 'title',
      },
      selected: [],
      headers: [
        {
          text: this.$t('_enabled'),
          value: 'enabled',
        },
        {
          text: this.$t('_title'),
          align: 'left',
          value: 'name',
        },
        {
          text: this.$t('_source'),
          align: 'left',
          value: 'source',
        },
        {
          text: this.$t('_type'),
          align: 'left',
          value: 'type',
        },
        { text: this.$t('_accountingMapping'), value: 'code' },
        { text: 'Bitwave ID', value: 'id' },
      ],
      connections: [],
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    categories() {
      const disabledConnectionIds = this.connections
        .filter((c) => {
          return c.category === ConnectionCategory.AccountingConnection && c.isDisabled;
        })
        .map((c) => {
          return c.id;
        });
      const categories = this.$store.getters['categories/CATEGORIES'];
      return categories
        .filter((c) => {
          return disabledConnectionIds.indexOf(c.accountingConnectionId) === -1;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    categoriesIsLoading() {
      return this.$store.getters['categories/CATEGORIES_ISLOADING'];
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    updateEnabledStatus(item) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        id: item.id,
        enabled: item.enabled,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!, $enabled: Boolean!) {
              updateCategory(orgId: $orgId, id: $id, enabled: $enabled) {
                name
                enabled
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then((res) => {
          // snackbar
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
