<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-tabs color="transparent" v-model="selectedTab">
        <v-tab>
          {{ $tc('_category', 2) }}
        </v-tab>
        <v-tab>
          {{ $tc('_contact', 2) }}
        </v-tab>
        <v-flex xs3 offset-xs6 class="text-xs-right">
          <create-category :refresh="refreshCategories" v-if="selectedTab === 0" />
          <create-contact :refresh="refresh" v-if="selectedTab === 1" />
        </v-flex>
        <v-tab-item>
          <categories ref="categoriesRef" />
        </v-tab-item>
        <v-tab-item>
          <contacts ref="contactsRef" />
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </v-layout>
</template>

<script>
import Categories from '../components/company/Categories.vue';
import Contacts from '../components/company/Contacts.vue';
import CreateCategory from '../components/company/CreateCategory';
import CreateContact from '../components/CreateContact';

export default {
  props: [],
  components: {
    Categories: Categories,
    Contacts: Contacts,
    CreateContact,
    CreateCategory,
  },
  data: () => ({
    selectedTab: 0,
  }),
  methods: {
    refreshCategories() {
      this.$refs.categoriesRef.refresh();
    },
    refresh() {
      this.$refs.contactsRef.refresh();
    },
  },
};
</script>
